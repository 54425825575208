import React, { Component } from 'react';
import { Card, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, CardFooter, InputGroup, InputGroupAddon, InputGroupText, CardHeader, CardBody, Row, Col } from "reactstrap";
import { renderToString } from 'react-dom/server'
import ImageUploader from '../../components/Products/ImageUploader/ImageUploader.jsx';
import { Label } from '../../components/UI/Form/Label/Label';
import { Prompt } from 'react-router-dom';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip'
import axios from 'axios';
import * as actionCreator from '../../store/action/index';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../../components/Loader/Loader';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Spinner from '../../components/Spinner/Spinner';
import { Line } from 'rc-progress';
import { Formik, Form, Field, FieldArray } from 'formik';


import {
    ProductList as ProductListAction,
    ProductCategory,
    ProductAdd,
    PurchaseAdd,
    ProductEdit,
    ProductUpdate,
    ProductCategoryGeneral,
    NewCategoryAction,
    AuthorIndex,
    AuthorCreate,
    MaterialIndex,
    MaterialCreate,
    ListEmployee
} from '../../api/index';


class ProductEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            name: '',
            categoryGeneral: {
                id: 4,
                label: "Book",
                value: 4
            },
            category: [
                // { id: 1, value: 'Pertanian', label: 'Pertanian' },
                // { id: 2, value: 'Peternakan', label: 'Peternakan' },
                // { id: 3,value: 'Teknologi', label: 'Teknologi' }
            ],
            description: '',
            create_price: 0,
            published_price: 0,
            base_price: 0,
            promo_price: 0,
            unit: "Pcs",
            publish_date: new Date(),
            weight: 0,
            isbn: '',
            status: 'draft',
            height: 0,
            width: 0,
            thick: 0,
            stock_level: 0,
            version: 1,
            production_version: 1,
            sku: '',
            language:'',
            eisbn:'',
            publisher:'',
            seoauthor:'',
            ppn: '0',
            stok: '0',
            format: null,
            material: [],
            author: [],
            pages: 0,
            category_general_options: [],
            category_options: [
                // { id: 1, value: 'Pertanian', label: 'Pertanian' },
                // { id: 2, value: 'Peternakan', label: 'Peternakan' },
                // { id: 3,value: 'Teknologi', label: 'Teknologi' }
            ],
            materials: [
                // {id:1, value: 'Book Paper', label:'Book Paper'},
                // {id:2, value: 'Soft Cover', label:'Soft Cover'},
                // {id:3, value: 'Art Carton', label:'Art Carton'},
            ],
            authors_options: [
                // {id:1, value: 'Author 1', label:'Author 1'},
                // {id:2, value: 'Author 2', label:'Author 2'},
                // {id:3, value: 'Author 3', label:'Author 3'},
            ],
            addCategory: false,
            addAuthor: false,
            addMaterial: false,
            saveable: true,
            addMode: null,
            modal: false,
            newCategory: {
                name: '',
            },
            newAuthor: {
                name: '',
                occupation: '',
                phone: ''
            },
            newMaterial: {
                name: '',
            },
            thumbnailFile: [],
            productImagesUrl: [],
            sumFilled: 0,
            formNotFilledYet: [],
            prompt: true,
            edit_status: "newpo",
            productId: null,
            show_button_cat: false,
            show_button_author: false,
            show_button_material: false,
            // Detail untuk buku
            tim_penulis: '-',
            mou_data: new Date(),
            source_fin_publisher: 0,
            source_fin_author: 0,
            source_fin_sponsor: 0,
            pp: 0,
            pb: 0,
            royalti_percent: 0,

            editor_product: null,
            layouter_product: null,
            desainer_product: null,
            manager_proyek: null,

            user_blst: []

        }
    }

    formatuang(amount) {
        if (amount === null) {
            amount = 0;
        }
        // deletecomma
        let comadel = amount.toString().replace(/\,/g, '');
        let price = comadel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");;
        return price;
    }

    onChangeMoneyHandler = (event) => {

        // validasi input tidak boleh huruf
        let values = event.target.value.toString().replace(/\,/g, '');
        // let isNum = /^\d+$/.test(values); // tanpakoma
        let isNum = /^[0-9]+\.?[0-9]*$/.test(values); // dengan koma output true or false                         
        let key = event.target.name;

        if (isNum || event.target.value === null) {
            this.setState({ [key]: parseFloat(values) })

            // jika yang diset adalah base_price, promo price akan mengikuti base price dan harus diisi
            if (key === "base_price") {
                this.setState({ promo_price: parseFloat(values) })
            }
        }
        else if (values.length <= 1) {
            this.setState({ [key]: 0 })

            // jika yang diset adalah base_price, promo price akan mengikuti base price dan harus diisi
            if (key === "base_price") {
                this.setState({ promo_price: 0 })
            }
        }
        this.countFilled();
    }

    onChangeNumberHandler = (event) => {

        // validasi input tidak boleh huruf
        let values = event.target.value.toString().replace(/\,/g, '');
        // let isNum = /^\d+$/.test(values); // tanpakoma
        let isNum = /^[0-9]+\.?[0-9]*$/.test(values); // dengan koma output true or false                         
        let key = event.target.name;

        while (values.charAt(0) === '0') {
            values = values.substr(1);
        }

        if (isNum || event.target.value === null) {
            this.setState({ [key]: (values) })
        }
        else if (values.length <= 1) {
            this.setState({ [key]: 0 })
        }
        this.countFilled();
    }

    newFormHandler = (event) => {
        event.preventDefault();
        let name = event.target.name;
        this.setState({ addAuthor: false, addMaterial: false, addCategory: false });
        this.setState({ [name]: true, modal: true })
    }

    hideModal = () => {
        this.setState({
            modal: !this.state.modal
        })
    }

    onUpdateHandler = (event) => {
        event.preventDefault();
        this.setState({ prompt: false }, () => {
            event.preventDefault();
            const content = this.state;
            ProductUpdate(content).then(res => {
                if (res.status === "success") {
                    const toaster = {
                        isOpenToast: true,
                        toastMessage: res.data.name + " Berhasil diUpdate",
                        toastType: 'success',
                    }
                    this.props.toggleToaster(toaster)
                    this.props.history.replace('/dashboard/products');
                    this.props.history.push('/dashboard/products');
                }
            }).catch(err => console.log(err))
        })
    }

    onSaveHandler = (event) => {
        this.setState({ prompt: false }, () => {
            event.preventDefault();
            const content = this.state;
            ProductAdd(content).then(res => {
                if (res.status === "success") {
                    const toaster = {
                        isOpenToast: true,
                        toastMessage: res.data.name + " Succesfully Added",
                        toastType: 'success',
                    }
                    this.props.toggleToaster(toaster)

                    toast.success(res.data.name + " Successfully Added");
                    this.props.history.replace('/dashboard/products');
                    this.props.history.push('/dashboard/products');
                }
            }).catch(err => {
                console.log(err);
                toast.warn("Whoops Something Error" + err);
            });
        })

    }

    // Image Processing 
    onDrop = (files, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            toast.error(rejectedFiles.length + " file ditolak karena melebihi ukuran 2MB. Ini akan berdampak pada kecepatan loading yang akan lama jika melebihi dari ukuran tersebut. Kasihan yang pakai koneksi esia hidayah :(")
        }

        this.setState({ saveable: false })
        const max_file_count = 4 - this.state.thumbnailFile.length;
        const array_images = this.state.thumbnailFile.concat(
            files.slice(0, max_file_count).map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })
            ));

        this.setState({ thumbnailFile: array_images });
        // uploading to cloudinary directly
        files.slice(0, max_file_count).map((file) => {
            this.handleUploadImages(file).then(() => {
                this.setState({ saveable: true })
                this.countFilled();
            });
        })

    }

    // This function does the uploading to cloudinary
    handleUploadImages = (image) => {
        // uploads is an array that would hold all the post methods for each image to be uploaded, then we'd use axios.all()

        // our formdata
        const formData = new FormData();
        formData.append("file", image);
        formData.append("tags", ['product']); // Add tags for the images - {Array}
        formData.append("upload_preset", "blst_product"); // Replace the preset name with your own
        formData.append("api_key", "387685966233372"); // Replace API key with your own Cloudinary API key
        formData.append("folder", "product");
        formData.append("quality", "low");
        formData.append("timestamp", (Date.now() / 1000) | 0);

        // Replace cloudinary upload URL with yours
        return axios.post(
            "https://api.cloudinary.com/v1_1/blst/image/upload",
            formData,
            { headers: { "X-Requested-With": "XMLHttpRequest" } })
            .then(response => {
                console.log(response.data)
                // const oldImages = this.state.productImagesUrl;
                const newImages = response.data;
                const newArrayofImages = this.state.productImagesUrl.concat(newImages);
                this.setState({ productImagesUrl: newArrayofImages });
            }).catch(err => console.log(err))
    }

    pushFormNotif = (sum) => {
        const notif = [];

        if (this.state.name === '') { notif.push("Nama Produk Belum diisi") };
        if (this.state.category.length === 0) { notif.push("Category belum diisi") };
        if (this.state.author.length === 0) { notif.push("Author belum diisi") };
        if (this.state.productImagesUrl.length === 0) { notif.push("Belum ada Gambar Produk") };
        if (this.state.category.material === 0) { notif.push("Material belum diisi") };
        if (this.state.categoryGeneral.length === 0) { notif.push("Category umum belum diisi") };
        if (this.state.description === '') { notif.push("Deskripsi belum diisi") };
        if (this.state.base_price === 0) { notif.push("Harga Produk belum diisi") };
        if (this.state.pp === 0) { notif.push("Harga Pokok belum diisi") };
        if (this.state.unit === '') { notif.push("Satuan Produk belum diisi") };
        if (this.state.weight === 0) { notif.push("Berat belum diisi") };
        if (this.state.height === 0) { notif.push("Dimensi Panjang Produk belum diisi") };
        // if(this.state.thick !== 0) { notif.push("Tebal Produk belum diisi") };
        if (this.state.isbn === '') { notif.push("ISBN belum diisi") };
        if (this.state.pb === 0) { notif.push("Harga Penerbit belum diisi") };
        if (this.state.royalti_percent === 0) { notif.push("Persen Royalti belum diisi") };
        if (this.state.pages === 0) { notif.push("Halaman belum diisi") };
        if (this.state.manager_proyek === null) { notif.push("Account Manager belum diisi") };
        if (this.state.editor_product === null) { notif.push("Editor Buku belum diisi") };
        if (this.state.layouter_product === null) { notif.push("Layouter Buku belum diisi") };
        if (this.state.desainer_product === null) { notif.push("Desainer Buku belum diisi") };

        this.setState({ formNotFilledYet: notif });
    }

    // Validation
    countFilled = () => {
        // basic
        const title = this.state.name !== '' ? 1 : 0;
        const category = this.state.category.length !== 0 ? 1 : 0;
        const author = this.state.author.length !== 0 ? 1 : 0;
        const productImagesUrl = this.state.productImagesUrl.length !== 0 ? 1 : 0;
        const material = this.state.category.material !== 0 ? 1 : 0;
        const categoryGeneral = this.state.categoryGeneral.length !== 0 ? 1 : 0;
        const description = this.state.description !== '' ? 1 : 0;
        const base_price = this.state.base_price !== 0 ? 1 : 0;
        const pp = this.state.pp !== 0 ? 1 : 0; // pokok penjualan
        const unit = this.state.unit !== '' ? 1 : 0;
        const weight = this.state.weight !== 0 ? 1 : 0;
        const height = this.state.height !== 0 ? 1 : 0;
        // const thick = this.state.thick !== 0 ? 1 : 0;

        // penerbit
        const isbn = this.state.isbn !== '' ? 1 : 0;
        const pb = this.state.pb > 0 ? 1 : 0; // penerbit
        const royalti_percent = this.state.royalti_percent > 0 ? 1 : 0; // penerbit
        const pages = this.state.pages !== 0 ? 1 : 0;
        const manager_proyek = this.state.manager_proyek !== null ? 1 : 0;
        const editor_product = this.state.editor_product !== null ? 1 : 0;
        const layouter_product = this.state.layouter_product !== null ? 1 : 0;
        const desainer_product = this.state.desainer_product !== null ? 1 : 0;



        const sum =
            [title,
                category,
                author,
                productImagesUrl,
                material,
                categoryGeneral,
                description,
                base_price,
                pp,
                weight,
                pages,
                height,
                // thick,
                unit,

                isbn,
                pb,
                royalti_percent,

                manager_proyek,
                editor_product,
                layouter_product,
                desainer_product
            ];

        this.pushFormNotif(sum);

        const sums = sum.reduce((a, b) => a + b, 0);
        const pembagi = sum.length;
        const percent = (sums / pembagi) * 100;

        this.setState({ sumFilled: Math.ceil(percent) })
    }


    deleteImageHandler = (event, index) => {
        event.preventDefault();
        const public_id = this.state.productImagesUrl[index];

        if (typeof public_id !== 'undefined') {
            axios.delete("https://api.cloudinary.com/v1_1/blst/image/upload?public_ids[]=" + public_id.public_id).then(res => {
                console.log(res)
            });
        }

        const del1 = this.state.thumbnailFile.splice(index, 1);
        const del2 = this.state.productImagesUrl.splice(index, 1);
        Promise.all([del1, del2]).then(() => {
        })
        this.setState({ thumbnailFile: this.state.thumbnailFile, productImagesUrl: this.state.productImagesUrl });
    }

    componentWillUnmount() {
        // Make sure to revoke the data uris to avoid memory leaks
        this.state.thumbnailFile.forEach(file => URL.revokeObjectURL(file.preview))
    }

    componentWillMount() {

    }

    componentDidMount() {
        window.scrollTo(0, 0);
        // call for CategoryGeneral
        this.props.setLoading(true)
        const categoryGeneral = [];
        const content = {}
        ProductCategoryGeneral(content).then(res => {
            res.map((value, key) => {
                categoryGeneral.push({
                    id: value.id,
                    value: value.id,
                    label: value.name
                });
            });

        }).then(res => {
            this.setState({ category_general_options: categoryGeneral });
            this.props.setLoading(false)
        }).catch(err =>
            toast.warn("Network Error, Can't get catogory data from server " + err));
        this.props.setLoading(false);

        // call for Category
        const category = [];
        ProductCategory().then(res => {
            res.map((value, key) => {
                category.push({
                    id: value.id,
                    value: value.name,
                    label: value.name
                })
            })
            this.setState({ category_options: category })
        })

        // call for Material
        const materials = [];
        MaterialIndex().then(res => {
            res.map((value, key) => {
                materials.push({
                    id: value.id,
                    value: value.id,
                    label: value.name
                })
            })
            this.setState({ materials: materials })
        });

        // call for author
        const authorsnya = [];
        AuthorIndex().then(res => {
            res.map((value, key) => {
                authorsnya.push({
                    id: value.id,
                    value: value.name,
                    label: value.name
                })
            })
            this.setState({ authors_options: authorsnya })
        });

        // call for employee
        const list_emp = [];
        const push_blst_employee = ListEmployee().then(res => {
            res.map((value, index) => {
                list_emp.push({
                    id: value.id_user,
                    value: value.name,
                    label: value.name
                })
            })
        }).then(() => {
            this.setState({ user_blst: list_emp });
        });




        // if status == edit
        if (this.props.match.params.status === "edit" || this.props.match.params.status === "duplicate") {
            this.setState({ isFetching: true });

            const content = {
                id: this.props.match.params.id
            }
            ProductEdit(content).then(res => { 
                               
                this.setState({ edit_status: this.props.match.params.status });
                const categories = [];
                const authors = [];
                const materials = [];
                const pictures = [];
                const picture_url = [];
                const push_cat = res.Categories.map((value, key) => {
                    categories.push({
                        id: value.id,
                        value: value.id,
                        label: value.name
                    })
                });

                const push_author = res.Authors.map((value, index) => {
                    authors.push({
                        id: value.id,
                        value: value.id,
                        label: value.name
                    })
                });

                const push_material = res.Materials.map((value, index) => {
                    materials.push({
                        id: value.id,
                        value: value.id,
                        label: value.name
                    })
                })

                const push_picture = res.Pictures.map((value, index) => {
                    const file = new File([], value.original_filename, { lastModified: value.updated_at });
                    pictures.push(
                        Object.assign(file, {
                            preview: value.url_medium
                        })
                    )

                    picture_url.push({
                        public_id: value.public_id,
                        alt: value.alt,
                        bytes: value.size,
                        original_filename: value.original_filename,
                        signature: value.signature
                    })

                })

                Promise.all([push_cat, push_author, push_material, push_picture, push_blst_employee]).then(() => {

                    const push_manager = this.state.user_blst.filter((user) => {
                        return new RegExp(res.manager_proyek, "i").exec(user.id);
                    });

                    const push_layouter_product = this.state.user_blst.filter((user) => {
                        return new RegExp(res.layouter_product, "i").exec(user.id);
                    });

                    const push_editor_product = this.state.user_blst.filter((user) => {
                        return new RegExp(res.editor_product, "i").exec(user.id);
                    });

                    const push_desainer_product = this.state.user_blst.filter((user) => {
                        return new RegExp(res.desainer_product, "i").exec(user.id);
                    });


                    if (push_manager[0] !== undefined) {
                        this.setState({ manager_proyek: push_manager[0] });
                        this.setState({ layouter_product: push_layouter_product[0] });
                        this.setState({ editor_product: push_editor_product[0] });
                        this.setState({ desainer_product: push_desainer_product[0] });
                    }

                    this.setState({ category: categories });
                    this.setState({ author: authors });
                    this.setState({ material: materials });
                    this.setState({ thumbnailFile: pictures });
                    this.setState({ productImagesUrl: picture_url });

                    this.setState({
                        name: res.name,
                        categoryGeneral: {
                            id: res.CategoryGeneral.id,
                            label: res.CategoryGeneral.name,
                            value: res.CategoryGeneral.id,
                        },
                        publish_date: res.publish_date,
                        base_price: res.base_price,
                        promo_price: res.promoPrice,
                        description: res.description,
                        weight: res.weight,
                        width: res.width,
                        height: res.height,
                        thick: res.thick,
                        version: res.version,
                        production_version: res.fabrication_version,
                        pages: res.pages,
                        isbn: res.isbn,
                        sku: res.sku,
                        language: res.language,
                        eisbn: res.eisbn,
                        publisher: res.publisher,
                        seoauthor: res.seoauthor,
                        ppn:res.ppn,
                        stok:res.stok,
                        saveable: true,
                        productId: res.id,
                        royalti_percent: res.royalti_percent !== null ? res.royalti_percent : 0,
                        mou_data: res.mou_data !== null ? res.mou_data : new Date(),
                        source_fin_publisher: res.source_fin_publisher !== null ? res.source_fin_publisher : 0,
                        source_fin_author: res.source_fin_author !== null ? res.source_fin_author : 0,
                        source_fin_sponsor: res.source_fin_sponsor !== null ? res.source_fin_sponsor : 0,
                        pp: res.pp !== null ? res.pp : 0,
                        pb: res.pb !== null ? res.pb : 0,

                    }, () => {
                        this.countFilled();
                        this.setState({ isFetching: false });
                    })

                })



            })
        }
    }


    // Handling untuk penambahan category, material, author
    AddButtonHandler = (event) => {
        event.preventDefault();
        const mode = this.state.addMode;

        if (mode === 'category') {
            const content = {
                name: this.state.newCategory.name
            }
            NewCategoryAction(content).then(res => {
                if (res.status === "success") {
                    toast.success("Material Added Successfully");
                    this.hideModal()
                    const addedCategory = {
                        id: res.data.id,
                        value: res.data.id,
                        label: res.data.name
                    }
                    const oldCategory = this.state.category;
                    const newCategory = oldCategory.concat(addedCategory);
                    this.setState({ category: newCategory })
                } else {
                    toast.warning("Error Please Reload");
                }
            }).then((res) => {
                const category = [];
                ProductCategory().then(res => {
                    res.map((value, key) => {
                        category.push({
                            id: value.id,
                            value: value.id,
                            label: value.name
                        })
                    })
                    this.setState({ category_options: category })
                });
            })
        }

        if (mode === 'author') {
            const content = {
                name: this.state.newAuthor.name,
                occupation: this.state.newAuthor.occupation,
                phone: this.state.newAuthor.phone
            }

            AuthorCreate(content).then(res => {
                if (res.status === "success") {
                    toast.success("Author Added Successfully");
                    this.hideModal();

                    const addedAuthor = {
                        id: res.data.id,
                        value: res.data.id,
                        label: res.data.name
                    }

                    const oldAuthor = this.state.author;
                    const newAuthor = oldAuthor.concat(addedAuthor);
                    this.setState({ author: newAuthor })
                } else {
                    toast.warning("Error Please Reload");
                }
            }).then((res) => {
                const authors = [];
                AuthorIndex().then(res => {
                    res.map((value, key) => {
                        authors.push({
                            id: value.id,
                            value: value.id,
                            label: value.name
                        })
                    })
                    console.log(authors)
                    this.setState({ authors_options: authors })
                });
            })
        }

        if (mode === 'material') {
            const content = {
                name: this.state.newMaterial.name,
            }
            MaterialCreate(content).then(res => {
                console.log(res)
                if (res.status === "success") {
                    toast.success("Material Added Successfully");
                    this.hideModal()
                    const addedMaterial = {
                        id: res.data.id,
                        value: res.data.id,
                        label: res.data.name
                    }

                    const oldMaterial = this.state.material;
                    const newMaterial = oldMaterial.concat(addedMaterial);
                    this.setState({ material: newMaterial })
                } else {
                    toast.warning("Error Please Reload");
                }
            }).then((res) => {
                const materials = [];
                MaterialIndex().then(res => {
                    res.map((value, key) => {
                        materials.push({
                            id: value.id,
                            value: value.id,
                            label: value.name
                        })
                    })
                    this.setState({ materials: materials })
                });
            })
        }

    }

    showToaster = (message) => {
        const snackBarOption = {
            isOpen: true,
            text: message
        };
    }

    // fungsi untuk mencari string di array digunakan di logic option
    searchStringInArray = (string, myArray) => {
        for (var i = 0; i < myArray.length; i++) {
            if (myArray[i].label === string) {
                return myArray[i];
            }
        }

    }



    render() {
        let modalform = null;
        let titlemodal = null;
        let status = false;

        let color = 'grey';
        if (this.state.sumFilled < 20) {
            color = '#fb5a5a';
        } else if (this.state.sumFilled < 40) {
            color = '#fbb35a';
        } else if (this.state.sumFilled < 60) {
            color = '#fbe75a';
        } else if (this.state.sumFilled < 80) {
            color = '#5ae5fb';
        } else {
            color = '#5bd400';
        }

        let datatipform = null;
        if (this.state.formNotFilledYet !== null) {
            datatipform = this.state.formNotFilledYet.map((value, index) => (
                <div>{value}</div>
            ))
        }


        if (this.state.addCategory) {
            titlemodal = "Add Category"
            status = this.state.newCategory.name === "";
            modalform =
                <Row>
                    <Col md={12}>
                        <Label for="nasme" required>Category Name <small>/ Nama Kategori</small></Label>
                        <Input value={this.state.newCategory.name} type="text" onChange={(event) => this.setState({ newCategory: { name: event.target.value }, addMode: 'category' })}></Input>
                    </Col>
                </Row>
        }

        if (this.state.addAuthor) {
            titlemodal = "Add Author"
            status = this.state.newAuthor.name === "" || this.state.newAuthor.phone === "";

            modalform =
                <Row>
                    <Col md={12}>
                        <Label for="name" required>Author Name <small>/ Nama Penulis</small></Label>
                        <Input type="text" value={this.state.newAuthor.name} onChange={(event) => {
                            const value = event.target.value;
                            this.setState((prevState) => ({
                                ...prevState,
                                addMode: 'author',
                                newAuthor: {
                                    ...prevState.newAuthor,
                                    name: value
                                }
                            }))
                        }}
                        ></Input>
                        <Label for="name" required>Phone Number <small>/ Nomor Telp</small></Label>
                        <Input type="text"
                            onChange={(event) => {
                                const value = event.target.value;
                                this.setState((prevState) => ({
                                    ...prevState,
                                    addMode: 'author',
                                    newAuthor: {
                                        ...prevState.newAuthor,
                                        phone: value
                                    }
                                }))
                            }}

                        ></Input>
                    </Col>
                </Row>
        }

        if (this.state.addMaterial) {
            titlemodal = "Add Material"
            status = this.state.newMaterial.name === "";
            modalform =
                <Row>
                    <Col md={12}>
                        <Label for="name" required>Material Name <small>/ Nama Bahan</small></Label>
                        <Input type="text" value={this.state.newMaterial.name} onChange={(event) => this.setState({ newMaterial: { name: event.target.value }, addMode: 'material' })}></Input>
                    </Col>
                </Row>
        }        

        return (

            <div className="content">
                {this.state.isFetching ? (<div style={{ width: '100%', height: '85vh' }}>
                    <Spinner></Spinner>
                </div>) : (
                        <div className="progress-bar-class">
                            <ReactTooltip />
                            <span>Filled Percentage <b>{this.state.sumFilled}%</b></span>
                            <Line data-html={true} data-tip={renderToString(datatipform)} percent={this.state.sumFilled} strokeWidth="2" strokeColor={color} />
                        </div>
                    )}
                <Prompt when={this.state.prompt} message="You have unsaved form data. Are you sure you want to leave?" />
                {/* Modal Tambah */}
                <Modal isOpen={this.state.modal} fade={false} toggle={this.hideModal}>
                    <form onSubmit={(event) => this.AddButtonHandler(event)}>
                        <ModalHeader>
                            {titlemodal}
                        </ModalHeader>
                        <ModalBody>
                            {modalform}
                            {status ? <Button disabled size="sm">Add</Button> : <Button onClick={(event) => this.AddButtonHandler(event)} size="sm">Add</Button>}
                        </ModalBody>
                    </form>
                </Modal>
    
                <div>
     <h1>Purchase Order</h1>
     <Formik
       initialValues={{ friends: ['jared', 'ian', 'brent'] }}
       onSubmit={values =>
         setTimeout(() => {
           alert(JSON.stringify(values, null, 2));
         }, 500)
       }
       render={({ values }) => (
         <Form>
           <FieldArray
             name="friends"
             render={arrayHelpers => (
               <div>
                 {values.friends && values.friends.length > 0 ? (
                   values.friends.map((friend, index) => (
                     <div key={index}>
                       <Field name={`friends.${index}`} />
                       <button
                         type="button"
                         onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                       >
                         -
                       </button>
                       <button
                         type="button"
                         onClick={() => arrayHelpers.insert(index, '')} // insert an empty string at a position
                       >
                         +
                       </button>
                     </div>
                   ))
                 ) : (
                   <button type="button" onClick={() => arrayHelpers.push('')}>
                     {/* show this when user has removed all friends from the list */}
                     Add PO
                   </button>
                 )}
                 <div>
                   <button type="submit">Submit</button>
                 </div>
               </div>
             )}
           />
         </Form>
       )}
     />
   </div>
        
        <Formik 
        
        
        
        />
                <form>
                    <Row>
                        <Col md={8} xs={12}>
                            {/* General Information */}
                            <Card className="card-user">
                                {/* <CardHeader>
                                    <h6>Purchase Order</h6>
                                </CardHeader> */}

                                <CardBody>
                                     <Col md={12}>
                                        <ReactTooltip />
                                        <Label for="name" required>Name Product <small data-tip="Hubungi tim IT untuk menambahan data jika tidak tersedia">/ Nama Produk</small></Label>
                                        <Select
                                            onChange={(val) => this.setState({ categoryGeneral: val }, () => { this.countFilled() })}
                                            name="categoryGeneral"
                                            value={this.state.categoryGeneral}
                                            className="basic-multi-select"
                                            options={this.state.category_general_options}
                                        />
                                    </Col>
                                    <Col md={12}>
                                        <Label for="name" required>Total <small>/ Jumlah</small></Label>
                                        <Input type="text" value={this.state.name} name="name" onChange={(event) => this.setState({ name: event.target.value }, () => { this.countFilled() })}></Input>
                                    </Col>
                              
                                </CardBody>
                                <CardFooter>

                                </CardFooter>
                            </Card>
                            


                            <Row >
                                <Col md={12} style={{ textAlign: 'right' }}>
                                    {this.state.saveable && this.state.sumFilled > 20  ? (
                                        <div>
                                            <Button onClick={(event) => this.props.history.push('/dashboard/products')} color="secondary">Cancel</Button>
                                           
                                            {this.state.edit_status !== "edit" ? (<Button onClick={this.onSaveHandler} color="success" >Save</Button>) : (<Button onClick={this.onUpdateHandler} color="success" >Update</Button>)}
                                        </div>
                                    ) : (
                                            <div>
                                                <Loader text="Waiting for you" />
                                            </div>
                                        )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </form>
                <ToastContainer />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        ui: state.ui
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        toggleNotif: (message) => dispatch(actionCreator.toggleNotification(message)),
        setLoading: (data) => dispatch(actionCreator.toggleLoading(data)),
        toggleToaster: (payload) => dispatch(actionCreator.toggleToaster(payload))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductEditor);
