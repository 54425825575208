import React, { Component } from 'react';
import {
    Card,
    CardHeader,
    CardBody,
    Input,
    Button,
    CardFooter,
    CardTitle,
    Row,
    Col
} from "reactstrap";
import { Label } from '../../components/UI/Form/Label/Label';
import DatePicker from "react-datepicker";
import Select from 'react-select';

import Toaster from '../../components/UI/Toaster/Toaster';
import OrderCard from '../../components/OrderCard/OrderCard.jsx';
import { ToastContainer, toast } from 'react-toastify';
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    IconButton,
    TableRow,
    TableCell,
    Collapse,
    Paper
} from '@material-ui/core';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Moment from 'react-moment';
import { TakeThisOrder } from '../../api/index';
import moment from 'moment';
import { read, utils, writeFileXLSX } from 'xlsx';




import { ListOrder, ConfirmSend, ListStatus, DeclineThisOrder, ListStokToko } from '../../api/index';


class StokToko extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFetching: false,
            // selectBy: { id: 2, value: 'Jumlah Transfer', label: 'Jumlah Transfer' },
            // selectByOptions: [
            //     { id: 1, value: 'Nomor Invoice', label: 'Nomor Invoice' },
            //     { id: 2, value: 'Jumlah Transfer', label: 'Jumlah Transfer' },
            // ],
            // paymentDate: new Date(),
            value: null,
            startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
            endDate: new Date(new Date().setHours(23, 59, 59, 999)),
            count: 0,
            // dataListOrderCompany: null,
            dataListStok: null,
            sumsubtotal: null,
            serambi: null,
            kitchen: null,
            fits: null,
            ipbpress: null,
            listStatus: [],
            companyId: null,
            // currentStatus: {
            //     id: 1,
            //     statusName: "Transaksi Berhasil"
            // },
            sortby: [
                {
                    id: 14,
                    value: "Serambi Botani",
                    label: "Serambi Botani"
                },
                {
                    id: 15,
                    value: "Kitchen",
                    label: "Kitchen"
                },
                {
                    id: 21,
                    value: "IPB Press",
                    label: "IPB Press"
                },
                {
                    id: 22,
                    value: "Fits Mandiri",
                    label: "Fits Mandiri"
                }
            ],

        }
    }

    fetchProduct = () => {
        this.setState({ isFetching: true })
        const date = moment(this.state.startDate).format('YYYY-MM-DD');;
        const dateEnd = moment(this.state.endDate).format('YYYY-M-DD');;
        // console.log(date)

        const dateTimeStart = date + " 00:00:00";
        const dateTimeEnd = dateEnd + " 23:59:59";
        const content = {
            querysearch: this.state.value,
            startDate: dateTimeStart,
            endDate: dateTimeEnd,
            companyId: this.state.companyId,
            page: 1
        }

        ListStokToko(content).then(result => {
            console.log(result.stok)
            this.setState({ dataListStok: result.stok })
        })
    }



    formatuang(amount) {
        if (amount === null) {
            amount = 0;
        }
        // deletecomma
        let comadel = amount.toString().replace(/\,/g, '');
        let price = comadel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");;
        return price;
    }

    onChangeMoneyHandler = (event) => {

        // validasi input tidak boleh huruf
        let values = event.target.value.toString().replace(/\,/g, '');

        // let isNum = /^\d+$/.test(values); // tanpakoma
        let isNum = /^[0-9]+\.?[0-9]*$/.test(values); // dengan koma output true or false                 

        let key = event.target.name;
        if (isNum || event.target.value === null) {
            this.setState({ [key]: parseInt(values) })
        }
        else if (values.length <= 1) {
            this.setState({ [key]: parseInt(0) })
        }

    }

    // fetchOrderCard = () => {
    //     this.setState({ isFetching: true })
    //     const content = {
    //         querysearch: this.state.value,
    //         startDate: this.state.startDate,
    //         endDate: this.state.endDate,
    //         statusId: [this.state.currentStatus.id],
    //         page: 1
    //     }

    //     ListOrder(content).then(result => {
    //         this.setState({ dataListOrderCompany: result.result, isFetching: false, count: result.result.length, sumsubtotal: result.subtotal[0].total });
    //           console.log(result.subtotal[0].total)
    //     })
    // }


    // status pengiriman
    // fetchListStatus = () => {
    //     this.setState({ isFetching: true });
    //     const content = {
    //         querysearch: this.state.value,
    //         startDate: this.state.startDate,
    //         endDate: this.state.endDate,
    //     };

    //     ListStatus(content).then(result => {
    //         this.setState({ listStatus: result.data });
    //     })
    // }



    queryInputChangeHandler = (event) => {
        event.preventDefault();
        this.setState({ value: event.target.value, dataListStok: null }, () => {
            this.fetchProduct();
            // this.fetchListStatus();
        })

    }

    handleChangeStart = (val) => {
        this.setState({ startDate: val, dataListStok: null }, () => {
            this.fetchProduct();
            // this.fetchListStatus();
        })
    }

    handleChangeEnd = (val) => {
        this.setState({ endDate: val, dataListStok: null }, () => {
            this.fetchProduct();
            // this.fetchListStatus();
        })
    }


    //handle excel
    handleExport = async () => {
        // const f = await (await fetch("https://sheetjs.com/pres.xlsx")).arrayBuffer();
        // const wb = read(f);
        // const data = utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
        // console.log(data);


        const data = [];
        await this.state.dataListStok.map((value, index) => {
            data.push({
                'Invoice' : value.transactionId,
                'Produk' : value.name,
                'Bank' : value.Order.Bank.bankname,
                'Harga' : value.price,
                'Qty' : value.qty,
                'Sub Total' : value.subtotal,
                'Tanggal Transaksi' : value.createdAt,
                'Company' : value.Company.companyName
            });          
        })
        const ws = utils.json_to_sheet(data);

        const wb2 = utils.book_new();
        utils.book_append_sheet(wb2, ws, "Data");
        writeFileXLSX(wb2, "ReportPenjualan.xlsx");

    }

    onSortByHandler = (val) => {
        this.setState({ companyId: val.id }, () => {
            // console.log(val.id)
            this.fetchProduct();
        });
    }



    // handleStatusClick = (e, value) => {
    //     this.setState({ currentStatus: value, dataListOrderCompany: null }, () => {
    //         this.fetchProduct();
    //         // this.fetchListStatus();
    //     });
    // }

    componentDidMount() {
        this.fetchProduct();
        // this.fetchListStatus();
    }





    renderTable = () => {
        const Row = (props) => {
            const { data } = props;
            const [open, setOpen] = React.useState(false);
            let ButtonCondition = null;


            return <>
                <TableRow onClick={() => setOpen(!open)}>
                    
                    <TableCell>{data.Product.name}</TableCell>
                    <TableCell>{data.Product.stok}</TableCell>
                    <TableCell>{data.Product.stok - data.jumlah_qty }</TableCell>
                    <TableCell>{data.jumlah_qty}</TableCell>
                    {/* <TableCell>{data.name}</TableCell>
                    <TableCell>{data.Order.Bank.bankname}</TableCell>
                    <TableCell>{this.formatuang(data.price)}</TableCell>
                    <TableCell>{data.qty}</TableCell>
                    <TableCell>{data.subtotal}</TableCell>
                    <TableCell>{data.createdAt}</TableCell>
                    <TableCell>{data.Company.companyName}</TableCell> */}
                    {/* <TableCell> <Button size="sm" style={{ margin: '0', borderRadius: '23px', background: data.StatusOrder.color }}>{data.StatusOrder.statusName}</Button></TableCell> */}

                    {/* <TableCell>{ButtonCondition}</TableCell> */}
                </TableRow>
                <TableRow>
                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <OrderCard DeclineItHandler={this.DeclineItHandler} history={this.props.history} OrderProps={data}></OrderCard>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </>
        }
        const rows = this.state.dataListStok.map((value, index) => {
            // console.log(value)          
            return <Row key={index} data={value}></Row>
        })



        return (<TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        {/* <TableCell /> */}
                        <TableCell>Produk</TableCell>
                        <TableCell>Stok Awal</TableCell>
                        <TableCell>Stok Toko</TableCell>
                        <TableCell>Jumlah Pembelian</TableCell>
                        <TableCell></TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows}
                </TableBody>

                <TableHead>
                    <TableRow>
                        <TableCell />
                        <TableCell></TableCell>
                        {/* <TableCell>SUB TOTAL </TableCell>
            <TableCell> {this.formatuang(this.state.sumsubtotal)}</TableCell> */}

                    </TableRow>




                </TableHead>

            </Table>
        </TableContainer>)

    }


    render() {
        let ListStokToko = <>
            <div className="loading-background" style={{ width: '100%', height: '40px', marginTop: '10px' }}></div>
            <div className="loading-background" style={{ width: '100%', height: '40px', marginTop: '10px' }}></div>
            <div className="loading-background" style={{ width: '100%', height: '40px', marginTop: '10px' }}></div>
        </>

        if (this.state.dataListStok !== null) {
            ListStokToko = this.renderTable()
            // listorder = this.state.dataListOrder.map((value, index) =>
            //     ("")
            //     // (<OrderCard DeclineItHandler={this.DeclineItHandler} history={this.props.history} OrderProps={value} key={index}></OrderCard>)
            // )
        }

        let listStatus = this.state.listStatus.map((value, index) => (
            <div key={index} onClick={(e) => this.handleStatusClick(e, value)} className={this.state.currentStatus.id === value.id ? "card-status-btn active-status" : "card-status-btn"} >
                Sub Total <span className="card-count-wrapper" style={{ background: `${value.color}` }}>{this.formatuang(this.state.sumsubtotal)}</span>
            </div>
        ));


        return (
            <>
                <ToastContainer />
                <div className="content">
                    <div className="otd-wrapper">
                        <div className="otd-header-wrapper">
                            <div className="search-input-wrap">
                                <Input onChange={(event) => { this.queryInputChangeHandler(event) }} placeholder="Cari Produk"></Input>
                            </div>
                            {/* <div className="count"><span className="count-title">Count :</span> {this.state.count}</div> */}
                            {/* <div className="count">
                                <Select
                                    onChange={(val) => this.onSortByHandler(val)
                                    }
                                    name="sortby"
                                    placeholder="Pilih Company"
                                    // value={this.state.sortbySelect}
                                    className="basic-multi-select"
                                    options={this.state.sortby}
                                />
                            </div> */}
                            <div className="date-input-filter">
                                <label style={{ padding: '0px 10px' }}><i className="nc-icon nc-calendar-60" /></label>
                                <DatePicker
                                    className="form-control datepicker-input"
                                    selected={this.state.startDate}
                                    dateFormat="dd-MM-yyyy"
                                    selectsStart
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    onChange={(val) => this.handleChangeStart(val)}
                                />
                                <span style={{ padding: '0px 10px' }}>-</span>
                                <DatePicker
                                    className="form-control datepicker-input"
                                    selected={this.state.endDate}
                                    dateFormat="dd-MM-yyyy"
                                    selectsEnd
                                    startDate={this.state.startDate}
                                    endDate={this.state.endDate}
                                    onChange={(val) => this.handleChangeEnd(val)}
                                />

                                
                            </div>
                            
                        </div>
                        
                        <div className="list-status-class">
                            
                            
                            {/* {listStatus} */}
                            {/* Total : {this.formatuang(this.state.sumsubtotal)} |
                            Serambi Botani : {this.formatuang(this.state.serambi)} |
                            Kitchen : {this.formatuang(this.state.kitchen)} |
                            Fits Mandiri : {this.formatuang(this.state.fits)} |
                            IPB Press : {this.formatuang(this.state.ipbpress)} |  */}
                            
                            
                        </div>
                        {ListStokToko}
                    </div>
                    {/* <Button onClick={() => this.handleExport()}>Export Excel</Button> */}
                   
                </div>
            </>
        )
    }
}

export default StokToko;