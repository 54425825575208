import Axios from "axios";
import React from "react";
import ReactDOM from "react-dom";
import { Card, Button, Table, Modal, ModalHeader, ModalBody, ModalFooter, Input, CardFooter, InputGroup, InputGroupAddon, InputGroupText, CardHeader, CardBody, Row, Col } from "reactstrap";
import { Label } from '../../components/UI/Form/Label/Label';
import { toast, ToastContainer } from 'react-toastify';


class Voucher extends React.Component {
    

    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
             data : [],
            numPage : 1,

            code: '',
            value: '',
            date_from: '',
            date_end: '',
            qty: '',
            minimal_cus: '',
            user_created: '',
            quota: '',
            percent: '',
            product_id: ''
        }
    }

     onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({numPage: numPages})
    }



    componentDidMount() {
    //  http://localhost:9212/voucher/index
        fetch('http://localhost:9212/voucher/index').then(res => res.json())
        .then(
            (result) => {
                console.log(result.result)
                this.setState({
                    data: result.result
                })

            },
            (error) => {
                this.setState({
                  isLoaded: true,
                  error
                });
              }
        )
    }

    

    inputData = () => {
        console.log(this.state)

        const data={
            code: this.state.code,
            value: this.state.value,
            date_from: this.state.date_from,
            date_end: this.state.date_end,
            qty: this.state.qty,
            minimal_cus: this.state.minimal_cus,
            user_created: this.state.user_created,
            product_id: this.state.product_id,
            quota: this.state.quota,
            percent: this.state.percent
        }

        return Axios.post(
            "http://localhost:9212/voucher/add",
            data
        ).then(response => {
            toast.success("Berhasil dibuat");
            window.location.href = '/dashboard/voucher'
        })


    }

    printQr = (id) => {
        const formData = new FormData();
        formData.append('id', id);

        return Axios.post(
            "https://system.ipbsciencetechnopark.com/api/get_qrcode",
            formData
        ).then(response => {
            console.log(response)
        })
        
    }

    DeletedQr = (id) => {
        const formData = new FormData();
        formData.append('id', id);

        return Axios.post(
            "https://system.ipbsciencetechnopark.com/api/deleted_qrcode",
            formData
        ).then(response => {
            toast.success("Berhasil dihapus");
          window.location.href = '/dashboard/qrcode'
        })
        
    }

    EditQr = (data) => {
        this.setState({location: data.location})
        
    }




    render() {
        return(

   


<div className="content">
    <form>
        <Row>
        <Col md={8} xs={12}>
        <Card className="card-user">
        <CardHeader>
            <h6>Input Voucher</h6>
        </CardHeader>
        <CardBody>
        <Col md={12}>
        <Label for="name" required>Code <small>/ Kode</small></Label>
        <Input type="text" value={this.state.code} name="name" onChange={(event) => this.setState({ code: event.target.value })}></Input>
        </Col>

        <Col md={12}>
        <Label for="name" required>Value <small>/ Nilai</small></Label>
        <Input type="text" value={this.state.value} name="name" onChange={(event) => this.setState({ value: event.target.value })}></Input>
        </Col>

        <Col md={12}>
        <Label for="name" required>Qty <small>/ Jumlah</small></Label>
        <Input type="text" value={this.state.qty} name="name" onChange={(event) => this.setState({ qty: event.target.value })}></Input>
        </Col>

        <Col md={12}>
        <Label for="name" required>Date Start <small>/ Tanggal Mulai</small></Label>
         <Input type="date" value={this.state.date_from} name="name" onChange={(event) => this.setState({ date_from: event.target.value })}></Input>
        </Col>

        <Col md={12}>
        <Label for="name" required>Date End <small>/ Tanggal Selesai</small></Label>
         <Input type="date" value={this.state.date_end} name="name" onChange={(event) => this.setState({ date_end: event.target.value })}></Input>
        </Col>


        </CardBody>

        <Button onClick={this.inputData} color="secondary">Save</Button>
            </Card>
        </Col>

        </Row>
        
        <Row>
            <Col xs={12}>
            <Card>
                <CardHeader>
                <h6>Voucher</h6>
                </CardHeader>

            <CardBody>
            <Table responsive>
                    <thead className="text-primary">
                        <tr>
                        <td>Kode Vocer</td>
                        <td>Nilai</td>
                        <td>Jumlah</td>
                        <td>Tanggal Mulai</td>
                        <td>Tanggal Akhir</td>
                        </tr>
                        
                    </thead>
                    <tbody>
                    {this.state.data && this.state.data.map((datas) => { 
                   return(
                    <tr>
                    <td>{datas.code}</td>
                    <td>{datas.value}</td>
                    <td>{datas.qty}</td>
                    <td>{datas.date_from}</td>
                    <td>{datas.date_end}</td>
                    <td>
  <div className="content">
   <div style={{ height: "auto", margin: "0 auto", maxWidth: 64, width: "100%" }}>
{/* <QRCode
size={256}
style={{ height: "auto", maxWidth: "100%", width: "100%" }}
value={`{"location":"${datas.location}"}`}
viewBox={`0 0 256 256`}
/> */}

</div>
    </div>


                    </td>
                    {/* <td> */}
                    {/* <button onClick={(e) => this.DeletedQr(datas.id)}>Delete</button> |  */}
                        {/* <a href={`https://system.ipbsciencetechnopark.com/api/get_qrcode/${datas.id}`}>Print QR</a> | */}
                        
                        {/* <button type="button" onClick={(e) => this.EditQr(datas)}>Edit</button> */}
                        {/* </td> */}
                        
                </tr>

                   )
                
                })}
                        
                       

                    </tbody>

             </Table>
                {/* {this.state.urlPDF != ''?
                <Document file={this.state.urlPDF} onLoadSuccess={this.onDocumentLoadSuccess}>
                    <Page pageNumber={this.state.numPage} />
                </Document>: null
                } */}

            </CardBody>


            </Card>
            </Col>
            

        </Row>




    </form>

    </div>




        )
        
    }



}

export default Voucher;


